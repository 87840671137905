import React, { useState } from 'react'
import { Container, Flex, Box, css, Text, Spinner, Button } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import { Formik } from 'formik';
import * as Yup from 'yup'
import { apiContacts } from '../../../../../site/api/api-contacts'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import envelopeIcon from '../../../../../site/content/assets/flexiblocks/icons/bx-envelope.svg'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import { trackActionSegment, NEWSLETTER_SIGNED } from '../../../../../site/segment/segment'

const styles = {
	form: {
		position: `relative`,
		width: ['85%', '100%'],
		margin: '0 auto'
	},
	responseOverlay: {
		position: `absolute`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: `transparent`,
		width: `full`,
		height: `105%`,
		transition: `background-color 350ms ease-in`,
		textAlign: `center`,
		zIndex: -1,
		p: 2,
		top: 0,
		left: 0,
		active: {
			zIndex: 0,
			backgroundColor: `rgba(255,255,255,0.85)`
		}
	},
	buttonsWrapper: {
		position: ['relative', 'absolute'],
		top: ['7px', '16px'],
		right: ['8px', '9px'],
		display: ['flex', `inline`],
		flexWrap: `no-wrap`,
		justifyContent: `center`,
		margin: ['0 auto', null],
		alignItems: ['center', null],
		justifyContent: ['center', null]
	}
}


const NewsletterInput = () => {

	const [isSubmitting, setSubmiting] = useState(false)
	const [hasError, setError] = useState(false)
	const [success, setSuccess] = useState(false)
	const [email, setEmail] = useState('')

	const errorMessage = "Houve um erro, tente novamente mais tarde"
	const successMessage = "Inscrição realizada com sucesso!"

	const successHandler = (email) => {
		setSubmiting(false)
		setError(false)
		setSuccess(true)
		trackActionSegment(NEWSLETTER_SIGNED, { "email": email })
	}

	const renderForm = ({
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit
	}) => {
		return (
			<>
				<form
					css={css(styles.form)}
					onSubmit={handleSubmit}
					noValidate
				>
					<Box variant='forms.row'>
						<Box
							key={`inputs-register`}
							variant={'forms.compact'}
						>
							<Box
								my={2}
								sx={errors?.email ?
									{ border: '2px solid #d9534f', borderRadius: '35px' } :
									{ border: '2px solid transparent' }}>
								<FormInput
									maxLength="320"
									icon={{ "src": envelopeIcon, "color": "alpha" }}
									type='TEXT'
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.email}
									value={values.email}
									name={'email'}
									id={'email'}
									placeholder={{ 'text': 'Insira seu e-mail' }}
								/>
							</Box>
						</Box>
					</Box>
					<Box sx={styles.buttonsWrapper}>
						<Button variant='gradient' type='submit' sx={{ padding: ['5px 15px', '2px 10px'] }}>INSCREVER-SE</Button>
					</Box>
					<Box
						sx={styles.responseOverlay}
						css={(isSubmitting || success || hasError) && styles.responseOverlay.active}
					>
						{isSubmitting && (
							<Reveal effect='fadeInDown'>
								<Spinner size='64' color='alpha' />
							</Reveal>
						)}
						{success && (
							<Reveal effect='fadeInDown'>
								<Text variant='small'>
									{successMessage}
								</Text>
								<BiCheckCircle size='64' css={css({ color: `success` })} />
							</Reveal>
						)}
						{hasError && (
							<Reveal effect='fadeInDown'>
								<Text variant='small'>
									{errorMessage}
								</Text>
								<BiErrorCircle size='64' css={css({ color: `error` })} />
							</Reveal>
						)}
					</Box>
				</form>
			</>
		);
	};


	const errorHandler = (error) => {
		setSubmiting(false)
		setSuccess(false)
		setError(true)
	}

	const submitForm = email => {
		setSubmiting(true)
		apiContacts.registerOnNewsletter(email.email)
			.then(() => successHandler(email.email))
			.catch(errorHandler)
	}

	return (
		<Container variant='cards.paper-lg' sx={{ maxWidth: '1176px' }}>
			<Flex sx={{
				flexDirection: ['column', 'row'],
				alignItems: 'center',
				justifyContent: 'space-around',
				width: ['100%', '90%'],
				margin: '0 auto'
			}}>
				<Box sx={{ width: ['100%', '50%'] }}>
					<Text variant='h4' sx={{ fontWeight: '600', textAlign: ['center', 'left'] }}>
						Receba mensalmente dicas sobre produtividade, direito e tecnologia, novidades do JUIT Rimor e muito mais.
					</Text>
				</Box>
				<Box sx={{ width: ['100%', '50%'] }}>
					<Formik
						initialValues={{ email: email }}
						enableReinitialize
						onSubmit={submitForm}
						render={renderForm}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email('Por favor, insira um e-mail válido')
								.required('Nós precisamos do seu email para liberar seu acesso a plataforma')
						})}
					/>
				</Box>
			</Flex>
		</Container >
	)
}

export default NewsletterInput
